<template>
  <section
    id="recommend"
    class="co-bg-striped py-12 sm:py-20"
  >
    <div class="co-container-xs">
      <h2 class="co-heading-2-caption">
        人気のコース
      </h2>
      <p class="mb-12 px-4 text-center text-co-gray-600">
        現役のプロが受講後の活躍まで見据え徹底サポート
        <br>
        最短4週間から自分のペースで学べます
      </p>
    </div>

    <!-- 申込締切 -->
    <TeTopBootcampDeadline
      class="mb-10 lg:mb-16"
      :cohort="cohort"
    />

    <!-- 副業 -->
    <div class="te-recommend-main-content mx-4 mb-10 rounded lg:mb-[3.75rem]">
      <!-- はじめての副業コース -->
      <div class="co-container-sm mb-5">
        <div class="flex flex-wrap">
          <div class="mb-5 w-full md:mb-0 md:basis-5/12">
            <a
              class="te-recommend-test-sidejob relative block hover:opacity-80"
              href="/course/first-sidejob"
            >
              <img
                class="te-course-popular absolute -left-3 -top-3"
                src="~/assets/images/Te/TeTop/Recommend/works_no1.svg"
                alt="人気No.1"
                width="52"
                height="52"
              >
              <div class="flex size-full flex-col items-center justify-center">
                <div class="co-display-8 flex items-center justify-center text-center font-bold text-white">
                  <FontAwesomeIcon
                    :icon="faHouseLaptop"
                    class="fa-fw te-course-icon mr-2 size-5"
                  />
                  はじめての副業コース
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:basis-7/12">
            <div class="ml-0 md:ml-8">
              <h4 class="te-recommend-title mb-4 hidden text-center font-bold md:block md:text-left">
                はじめての副業コース
              </h4>
              <ul class="mb-0 list-none px-4 md:px-0">
                <li class="mb-2">
                  <FontAwesomeIcon
                    :icon="faCheck"
                    class="fa-fw mr-2 inline size-4 text-[#1F7F96]"
                  />
                  <span class="font-bold">
                    <span class="co-marker-yellow-1">Web制作の副業に必要なスキル</span>が学べる
                  </span>
                </li>
                <li class="mb-2">
                  <FontAwesomeIcon
                    :icon="faCheck"
                    class="fa-fw mr-2 inline size-4 text-[#1F7F96]"
                  />
                  <span class="font-bold">学びに夢中になれる最先端の<span class="co-marker-yellow-1">イマーシブラーニング</span></span>
                </li>
                <li>
                  <FontAwesomeIcon
                    :icon="faCheck"
                    class="fa-fw mr-2 inline size-4 text-[#1F7F96]"
                  />
                  <span class="te-marker-text font-bold">手厚いサポートで<span class="co-marker-yellow-1">副業を最短でスタート</span></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 副業サポートの特長 -->
      <div class="co-container-lg mb-10 px-4 md:mb-[3.75rem]">
        <h5 class="co-display-10 mb-8 text-center font-bold">
          副業サポートの特長
        </h5>
        <div class="mb-7 flex flex-wrap justify-center md:mb-10">
          <TeCommonBoxCircleGreen class="te-box-circle mb-8 md:mr-3 lg:mb-0 lg:mr-6">
            <template #number>
              <span
                class="co-display-9 font-bold leading-[0] text-white"
              >1</span>
            </template>
            <template #text>
              <div class="px-6 pb-5 pt-8">
                <p
                  class="co-display-10 mb-3 text-center font-bold leading-normal"
                >
                  仕事をご紹介
                </p>
                <p class="m-0">
                  <span class="font-bold text-co-primary-500">5万円分の案件提供を保証！</span>
                  <br>
                  確実に副業をスタートできます。
                </p>
              </div>
            </template>
          </TeCommonBoxCircleGreen>
          <TeCommonBoxCircleGreen class="te-box-circle mb-8 md:ml-3 md:mr-0 lg:mb-0 lg:ml-0 lg:mr-6">
            <template #number>
              <span
                class="co-display-9 font-bold leading-[0] text-white"
              >2</span>
            </template>
            <template #text>
              <div class="px-6 pb-5 pt-8">
                <p
                  class="co-display-10 mb-3 text-center font-bold leading-normal"
                >
                  作業〜納品までサポート
                </p>
                <p class="m-0">
                  <span class="block sm:inline xl:block">仕事の進行管理・不明点の回答など</span>
                  <span class="font-bold text-co-primary-500">プロが納品までサポート</span>します。
                </p>
              </div>
            </template>
          </TeCommonBoxCircleGreen>
          <TeCommonBoxCircleGreen class="te-box-circle">
            <template #number>
              <span
                class="co-display-9 font-bold leading-[0] text-white"
              >3</span>
            </template>
            <template #text>
              <div class="px-6 pb-5 pt-8">
                <p
                  class="co-display-10 mb-3 text-center font-bold leading-normal"
                >
                  継続して稼げる
                </p>
                <p class="m-0">
                  <span class="block sm:inline xl:block">報酬と労力が適正な仕事だから、</span>
                  <span class="font-bold text-co-primary-500">安定した収入</span>を目指せます。
                </p>
              </div>
            </template>
          </TeCommonBoxCircleGreen>
        </div>
        <div class="text-center">
          <a
            class="co-btn-primary"
            href="/course/first-sidejob"
          >
            はじめての副業コースへ
            <FontAwesomeIcon
              :icon="faAngleRight"
              class="fa-fw inline h-3 w-2"
            />
          </a>
        </div>
      </div>
    </div>

    <!-- Webエンジニア転職コース -->
    <div class="te-recommend-main-content mx-4 mb-10 rounded lg:mb-[3.75rem]">
      <div class="co-container-sm mb-5">
        <div class="flex flex-wrap">
          <div class="mb-5 w-full md:mb-0 md:basis-5/12">
            <a
              class="te-recommend-test-webengineer-jobchange relative block hover:opacity-80"
              href="/set/webengineer-jobchange"
            >
              <div class="flex size-full flex-col items-center justify-center">
                <div class="co-display-8 flex items-center justify-center text-center font-bold text-white">
                  Webエンジニア転職保証コース
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:basis-7/12">
            <div class="ml-0 md:ml-8">
              <h4 class="te-recommend-title mb-4 hidden text-center font-bold md:block md:text-left">
                Webエンジニア転職保証コース
              </h4>
              <ul class="mb-0 list-none px-4 md:px-0">
                <li class="mb-2">
                  <FontAwesomeIcon
                    :icon="faCheck"
                    class="fa-fw mr-2 inline size-4 text-[#1F7F96]"
                  />
                  <span class="font-bold">
                    <span class="co-marker-yellow-1">Webエンジニア</span>に必要なスキルが学べる
                  </span>
                </li>
                <li class="mb-2">
                  <FontAwesomeIcon
                    :icon="faCheck"
                    class="fa-fw mr-2 inline size-4 text-[#1F7F96]"
                  />
                  <span class="font-bold">
                    <span class="co-marker-yellow-1">転職保証制度付き。</span>未経験でもエンジニアを目指せる
                  </span>
                </li>
                <li>
                  <FontAwesomeIcon
                    :icon="faCheck"
                    class="fa-fw mr-2 inline size-4 text-[#1F7F96]"
                  />
                  <span class="te-marker-text font-bold">転職活動がはじめての方でも安心の<span class="co-marker-yellow-1">手厚いサポート</span></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="co-container-lg mb-10 px-4 md:mb-[3.75rem]">
        <h5 class="co-display-10 mb-8 text-center font-bold">
          転職サポートの特長
        </h5>
        <div class="mb-7 flex flex-wrap justify-center md:mb-10">
          <TeCommonBoxCircleGreen class="te-box-circle mb-8 md:mr-3 lg:mb-0 lg:mr-6">
            <template #number>
              <span
                class="co-display-9 font-bold leading-[0] text-white"
              >1</span>
            </template>
            <template #text>
              <div class="px-6 pb-5 pt-8">
                <p
                  class="co-display-10 mb-3 text-center font-bold leading-normal"
                >
                  はじめての転職でも安心
                </p>
                <p class="m-0">
                  履歴書・職務経歴書の書き方・面接対策など
                  <br>
                  <span class="font-bold text-co-primary-500">専属のキャリアカウンセラー</span>がサポート
                </p>
              </div>
            </template>
          </TeCommonBoxCircleGreen>
          <TeCommonBoxCircleGreen class="te-box-circle mb-8 md:ml-3 md:mr-0 lg:mb-0 lg:ml-0 lg:mr-6">
            <template #number>
              <span
                class="co-display-9 font-bold leading-[0] text-white"
              >2</span>
            </template>
            <template #text>
              <div class="px-6 pb-5 pt-8">
                <p
                  class="co-display-10 mb-3 text-center font-bold leading-normal"
                >
                  返金保証制度
                </p>
                <p class="m-0">
                  <span class="block sm:inline xl:block">保証制度の条件を満たし、転職できなかった</span>場合、<span class="font-bold text-co-primary-500">受講料を全額返金</span>します。
                </p>
              </div>
            </template>
          </TeCommonBoxCircleGreen>
          <TeCommonBoxCircleGreen class="te-box-circle">
            <template #number>
              <span
                class="co-display-9 font-bold leading-[0] text-white"
              >3</span>
            </template>
            <template #text>
              <div class="px-6 pb-5 pt-8">
                <p
                  class="co-display-10 mb-3 text-center font-bold leading-normal"
                >
                  オススメの求人を紹介
                </p>
                <p class="m-0">
                  <span class="block sm:inline xl:block">1000社以上の求人から</span>
                  <span class="font-bold text-co-primary-500">あなたにマッチする</span>企業をご紹介
                </p>
              </div>
            </template>
          </TeCommonBoxCircleGreen>
        </div>
        <div class="text-center">
          <a
            class="co-btn-primary"
            href="/set/webengineer-jobchange"
          >
            Webエンジニア転職保証コースへ
            <FontAwesomeIcon
              :icon="faAngleRight"
              class="fa-fw inline h-3 w-2"
            />
          </a>
        </div>
      </div>
    </div>

    <!-- オーダーメイドコース -->
    <div class="te-recommend-main-content mx-4 mb-10 rounded lg:mb-[3.75rem]">
      <div class="co-container-sm mb-5">
        <div class="flex flex-wrap">
          <div class="mb-5 w-full md:mb-0 md:basis-5/12">
            <a
              class="te-recommend-order-made relative block hover:opacity-80"
              href="/course/ordermade"
            >
              <img
                class="te-course-popular absolute -left-3 -top-3"
                src="~/assets/images/Te/TeTop/Recommend/pickup.svg"
                alt="Pickup"
                width="52"
                height="52"
              >
              <div class="flex size-full flex-col items-center justify-center">
                <div class="co-display-8 flex items-center justify-center text-center font-bold text-white">
                  オーダーメイドコース
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:basis-7/12">
            <div class="ml-0 md:ml-8">
              <h4 class="te-recommend-title mb-4 hidden text-center font-bold md:block md:text-left">
                オーダーメイドコース
              </h4>
              <ul class="mb-0 list-none px-4 md:px-0">
                <li class="mb-2">
                  <FontAwesomeIcon
                    :icon="faCheck"
                    class="fa-fw mr-2 inline size-4 text-[#1F7F96]"
                  />
                  <span class="hidden font-bold md:inline">
                    <span class="co-marker-yellow-1">20種類以上のコース</span>から必要なスキルを自由に選べる
                  </span>
                  <span class="inline font-bold md:hidden">
                    <span class="co-marker-yellow-1">20コース以上</span>からスキルを選べる
                  </span>
                </li>
                <li class="mb-2">
                  <FontAwesomeIcon
                    :icon="faCheck"
                    class="fa-fw mr-2 inline size-4 text-[#1F7F96]"
                  />
                  <span class="hidden font-bold md:inline">ライフスタイルに合った<span class="co-marker-yellow-1">受講期間</span>で無理なく学べる</span>
                  <span class="inline font-bold md:hidden">自分に合った<span class="co-marker-yellow-1">受講期間</span>で学べる</span>
                </li>
                <li>
                  <FontAwesomeIcon
                    :icon="faCheck"
                    class="fa-fw mr-2 inline size-4 text-[#1F7F96]"
                  />
                  <span class="hidden font-bold md:inline"><span class="te-marker-text">予算に応じた受講料</span>を、相談しながら柔軟に決められる</span>
                  <span class="inline font-bold md:hidden"><span class="te-marker-text">受講料</span>を相談しながら決められる</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="co-container-lg mb-10 px-4 md:mb-[3.75rem]">
        <div class="text-center">
          <a
            class="co-btn-primary"
            href="/course/ordermade"
          >
            オーダーメイドコースへ
            <FontAwesomeIcon
              :icon="faAngleRight"
              class="fa-fw inline h-3 w-2"
            />
          </a>
        </div>
      </div>
    </div>

    <!-- コース一覧 -->
    <div class="co-container-sm px-4 text-left md:text-center lg:px-0">
      <div class="te-course-popular-inner justify-center">
        <a
          class="co-border-colors-gray-5 te-course-block relative border bg-white hover:opacity-80"
          href="/course/webdesign"
        >
          <img
            class="te-course-popular absolute"
            src="~/assets/images/Te/TeTop/BootcampRecommend/guarantee-icon.svg"
            alt="案件保証"
          >
          <div class="te-course-title te-bg-webdesign-trans-70 flex flex-col justify-center text-center font-bold text-white">
            <FontAwesomeIcon
              :icon="faObjectGroup"
              class="fa-fw te-course-icon mx-auto mb-2 block size-5"
            />
            Webデザインコース
          </div>
          <div class="te-course-caption p-2 text-left text-co-gray-700 md:p-3 lg:p-4">
            バナー・Webサイトのスキルを学び転職・副業も目指せる
          </div>
        </a>
        <a
          class="co-border-color-gray-5 te-course-block border bg-white hover:opacity-80"
          href="/course/first-prompt-engineering"
        >
          <div class="te-course-title te-bg-prompt-engineering-trans-80 flex flex-col justify-center text-center font-bold text-white">
            <FontAwesomeIcon
              :icon="faComment"
              class="fa-fw te-course-icon mx-auto mb-2 block size-5"
            />
            <span>プロンプトエンジニアリング</span>
            <span class="block">コース</span>
          </div>
          <div class="te-course-caption p-2 text-left text-co-gray-700 md:p-3 lg:p-4">
            Chat GPTなどのAIを使いこなし業務の生産性を向上
          </div>
        </a>
        <a
          class="co-border-color-gray-5 te-course-block border bg-white hover:opacity-80"
          href="/course/python"
        >
          <div class="te-course-title te-bg-python-trans-70 flex flex-col justify-center text-center font-bold text-white">
            <FontAwesomeIcon
              :icon="faPython"
              class="fa-fw te-course-icon mx-auto mb-2 block size-5"
            />
            Pythonコース
          </div>
          <div class="te-course-caption p-2 text-left text-co-gray-700 md:p-3 lg:p-4">
            AIエンジニアの入り口、機械学習・AIの基礎を習得
          </div>
        </a>
        <a
          class="co-border-colors-gray-5 te-course-block border bg-white hover:opacity-80"
          href="/course/java"
        >
          <div class="te-course-title te-bg-java-trans-70 flex flex-col justify-center text-center font-bold text-white">
            <FontAwesomeIcon
              :icon="faJava"
              class="fa-fw te-course-icon co-display-9 mx-auto mb-2 block size-5"
            />
            Javaコース
          </div>
          <div class="te-course-caption p-2 text-left text-co-gray-700 md:p-3 lg:p-4">
            システム開発やWeb開発に使われるJavaで開発を実践
          </div>
        </a>
        <a
          class="co-border-colors-gray-5 te-course-block border bg-white hover:opacity-80"
          href="/course/frontend"
        >
          <div class="te-course-title te-bg-frontend-trans-70 flex flex-col justify-center text-center font-bold text-white">
            <FontAwesomeIcon
              :icon="faCode"
              class="fa-fw te-course-icon co-display-9 mx-auto mb-2 block size-5"
            />
            フロントエンドコース
          </div>
          <div class="te-course-caption p-2 text-left text-co-gray-700 md:p-3 lg:p-4">
            Webサービス画面を人気の技術で作成
          </div>
        </a>
        <a
          class="co-border-colors-gray-5 te-course-block border bg-white hover:opacity-80"
          href="/course/ai"
        >
          <div class="te-course-title te-bg-ai-trans-70 flex flex-col justify-center text-center font-bold text-white">
            <FontAwesomeIcon
              :icon="faHeadSideBrain"
              class="fa-fw te-course-icon co-display-9 mx-auto mb-2 block size-5"
            />
            AIコース
          </div>
          <div class="te-course-caption p-2 text-left text-co-gray-700 md:p-3 lg:p-4">
            Pythonで人工知能(AI)・データ分析の実践
          </div>
        </a>
      </div>
    </div>
    <div class="mb-[3.25rem] mt-7 text-center md:mt-10">
      <a
        class="co-btn-primary"
        href="/course"
      >コース一覧はこちら</a>
    </div>
    <div class="text-center">
      <h3 class="co-display-11 mb-5 px-4 text-center font-bold">
        動画編集で副業・フリーランスを目指すなら
      </h3>
      <a
        class="co-btn-secondary"
        :href="'https://techacademy.jp/moocres'"
        target="_blank"
      >
        ムークリのサイトを見る
        <FontAwesomeIcon
          :icon="faArrowUpRightFromSquare"
          class="fa-fw inline h-4 w-5"
        />
      </a>
      <div class="te-btn-caption mt-3 px-4 text-co-gray-500">
        ※ムークリはテックアカデミーと同じ会社が提供するスクールです
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { getLatestCohort, getFirstOpenedWholeCohort } from '~/utility/fetchAPI'
import TeTopBootcampDeadline from '~/components/Te/TeTop/TeTopBootcampDeadline.vue'
import TeCommonBoxCircleGreen from '~/components/Te/TeCommon/Box/TeCommonBoxCircleGreen/TeCommonBoxCircleGreen.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHouseLaptop, faObjectGroup, faCode } from '@awesome.me/kit-65d95819ce/icons/classic/regular'
import { faPython, faJava } from '@awesome.me/kit-65d95819ce/icons/classic/brands'
import { faHeadSideBrain, faCheck, faAngleRight, faComment, faArrowUpRightFromSquare } from '@awesome.me/kit-65d95819ce/icons/classic/solid'

const options = {
  async setup() {
    return {
      faHouseLaptop,
      faPython,
      faJava,
      faHeadSideBrain,
      faCheck,
      faAngleRight,
      faObjectGroup,
      faComment,
      faCode,
      faArrowUpRightFromSquare,
    }
  },
  data() {
    return {
      cohort: null,
      firstOpenedWholeCohort: null,
    }
  },
  mounted() {
    getLatestCohort(this.$api).then((response) => {
      this.cohort = response.data
    })
    getFirstOpenedWholeCohort(this.$api).then((response) => {
      this.firstOpenedWholeCohort = response.data
    })
  },
  components: {
    TeTopBootcampDeadline,
    TeCommonBoxCircleGreen,
    FontAwesomeIcon,
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.te-recommend-title {
  font-size: 1.25rem;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

/* // TeCommonBoxCircle */
.te-box-circle {
  width: 100%;
  @media (min-width: 768px) {
    width: calc((100% - 24px) / 2);
  }
  @media (min-width: 992px) {
    width: calc((100% - 48px) / 3);
  }
}

.te-marker-text {
  @media (max-width: 575.98px) {
    font-size: 0.843rem;
  }
}

/* // ピックアップコースの外枠 */
.te-recommend-main-content {
  border: 1px solid #e5e5e5;
  @media (min-width: 768px) {
    border: none;
  }
}

.te-course-icon {
  font-size: 1.25rem;
  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
}

.te-recommend-test-sidejob {
  height: 11.875rem;
  background: linear-gradient(
      rgba(137, 169, 94, 0.7) 0%,
      rgba(137, 169, 94, 0.7) 100%
    ),
    url('~/assets/images/Te/TeTop/Recommend/bg-first-sidejob.jpg');
  background-repeat: no-repeat;
  background-position: center, 2.5rem center;
  background-size: cover;
  border-radius: 0.25rem 0.25rem 0 0;
  @media (min-width: 768px) {
    border-radius: 0;
  }
}

.te-recommend-test-webengineer-jobchange {
  height: 11.875rem;
  background: linear-gradient(
      rgba(78, 187, 218, 0.7) 0%,
      rgba(78, 187, 218, 0.7) 100%
    ),
    url('~/assets/images/Te/TeCourse/Index/CareerChange/bg-webengineer-jobchange.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0.25rem 0.25rem 0 0;
  @media (min-width: 768px) {
    border-radius: 0;
  }
}

.te-recommend-order-made {
  height: 11.875rem;
  background: url('~/assets/images/Te/TeTop/Recommend/bg-order-made.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0.25rem 0.25rem 0 0;
  @media (min-width: 768px) {
    border-radius: 0;
  }
}

/* // コース紹介の各コース導線 */
.te-course-popular-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.te-course-block {
  border-radius: 6px;
  .te-course-title {
    height: 92px;
    font-size: 0.75rem;
    border-radius: 6px 6px 0 0;
    @media (min-width: 576px) {
      font-size: 0.875rem;
    }
    @media (min-width: 992px) {
      height: 120px;
      font-size: 1.125rem;
    }
    .te-course-icon {
      font-size: 1.25rem;
      @media (min-width: 992px) {
        font-size: 1.5rem;
      }
    }
    &.te-bg-python-trans-70 {
      background: linear-gradient(
          rgba(62, 123, 175, 0.7) 0%,
          rgba(62, 123, 175, 0.7) 100%
        ),
        url('~/assets/images/Te/TeCourse/Index/Ai/bg-ai.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-webdesign-trans-70 {
      background: linear-gradient(
          rgba(205, 191, 73, 0.7) 0%,
          rgba(205, 191, 73, 0.7) 100%
        ),
        url('~/assets/images/Te/TeCourse/Index/WebSite/bg-website.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-ai-trans-70 {
      background: linear-gradient(
          rgba(0, 191, 208, 0.7) 0%,
          rgba(0, 191, 208, 0.7) 100%
        ),
        url('~/assets/images/Te/TeCourse/Index/Ai/bg-ai.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-frontend-trans-70 {
      background: linear-gradient(
          rgba(140, 122, 203, 0.7) 0%,
          rgba(140, 122, 203, 0.7) 100%
        ),
        url('~/assets/images/Te/TeCourse/Index/WebService/bg-webservice.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-java-trans-70 {
      background: linear-gradient(
          rgba(248, 152, 32, 0.7) 0%,
          rgba(248, 152, 32, 0.7) 100%
        ),
        url('~/assets/images/Te/TeCourse/Index/WebService/bg-webservice.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-prompt-engineering-trans-80 {
      background: linear-gradient(
          rgba(138, 170, 211, 0.8) 0%,
          rgba(62, 129, 213, 0.8) 100%
        ),
        url('~/assets/images/Te/TeCourse/Index/PromptEngineering/bg-prompt-engineering.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  .te-course-caption {
    font-size: 0.75rem;
    border-radius: 0 0 6px 6px;
    @media (min-width: 768px) {
      font-size: 0.875rem;
    }
    @media (min-width: 992px) {
      font-size: 1rem;
    }
  }
  .te-course-popular {
    top: -0.75rem;
    left: -0.5rem;
    z-index: 1;
    width: 2.25rem;
    height: 2.25rem;
    @media (min-width: 768px) {
      top: -0.5rem;
      width: 3.25rem;
      height: 3.25rem;
    }
  }
}

.te-btn-caption {
  font-size: 0.625rem;
  @media (min-width: 768px) {
    font-size: 0.75rem;
  }
}

/* マーカー */
.co-marker-yellow-1 {
  background: linear-gradient(transparent 70%, #ff3 0%);
}
</style>
